/**
 * Displays the under construction page
 *
 * @file   InWork.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'

import UnderConstruction from "./../assets/img/under-construction.webp"

import "./../styles/theme.scss";

const InWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Under Construction</title>
      </Helmet>

      <div className="under-construction">
        <img src={UnderConstruction} alt="404 page not found" />
      </div>
    </>
  );
};

export default InWork;