/**
 * Displays the Ideas page with mood themes
 *
 * @file   IdeasMood.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import PinBoards from "./PinBoards.jsx";

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const pinURL = "https://www.pinterest.com/jsummerphotos"

const themes = [
  { id: 1, title: "Dramatic", url: pinURL + "/mood-themes/dramatic/", desc: "Dramatic portrait photography captures the essence of the subject through intense emotion, striking contrasts, and compelling storytelling. It often features bold lighting that carves out the subject's features, creating deep shadows and highlights that evoke a sense of mystery or intrigue." },
  { id: 2, title: "Dreamy", url: pinURL + "/mood-themes/dreamy/", desc: "Dreamy portrait photography captures the essence of a whimsical, ethereal atmosphere, often characterized by soft focus, gentle lighting, and a serene expression. It's about creating an image that feels like a fleeting moment from a beautiful dream." },
  { id: 3, title: "Joyful", url: pinURL + "/mood-themes/joyful/", desc: "Joyful portrait photography captures the essence of happiness and contentment in its subjects. It's all about those spontaneous moments where smiles are genuine and eyes sparkle with delight. The lighting is often bright and natural, enhancing the cheerful mood." },
  { id: 4, title: "Mystery", url: pinURL + "/mood-themes/mystery/", desc: "Mystery portrait photography captures the essence of enigma and intrigue. It's about creating an atmosphere that suggests a hidden story or secret waiting to be uncovered." },
  { id: 5, title: "Romantic", url: pinURL + "/mood-themes/romantic/", desc: "Romantic portrait photography captures the essence of affection and connection between two people. It's about creating an atmosphere that reflects their love and intimacy. The focus is often on the interaction and emotion shared by the couple, whether it's a tender gaze, a soft touch, or a joyful embrace." },
  { id: 6, title: "Sad", url: pinURL + "/mood-themes/sad/", desc: "Sad portrait photography captures the essence of melancholy through visual storytelling. It often features subdued lighting, muted colors, and thoughtful composition to evoke a sense of introspection and sorrow." },
  { id: 7, title: "Serene", url: pinURL + "/mood-themes/serene/", desc: "Serene portrait photography captures the essence of tranquility and peace within a subject. It often features soft, natural lighting that gently illuminates the subject's features, creating a warm and inviting atmosphere." }
]

const IdeasMood = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Mood Themes</title>
      </Helmet>

      <div className="ideas-image">
        <h1>Ideas</h1>
      </div>

      <div className="ideas-div">
        <div className="ideas-nav-div">
          <div className="ideas-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/general"}>General</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/ideas/mood"}>Mood</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/active"}>Active</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/seasonal"}>Seasonal</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <PinBoards themes={themes} />
      </div>
    </>
  );
};

export default IdeasMood;