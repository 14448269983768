/**
 * Displays the blog page
 *
 * @file   Blog.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'

import BlogSummary from './../components/blog/BlogSummary.jsx'

import { KingCountyBlogInfo } from "./../components/blog/KingCountyLocations.jsx"
import { PierceCountyBlogInfo } from "./../components/blog/PierceCountyLocations.jsx"

import "./../styles/theme.scss";
import "./../styles/blog.scss";

const stories = [
  {
    id: 1,
    title: { KingCountyBlogInfo }.KingCountyBlogInfo.title,
    image: { KingCountyBlogInfo }.KingCountyBlogInfo.img,
    url: { KingCountyBlogInfo }.KingCountyBlogInfo.url,
    desc: { KingCountyBlogInfo }.KingCountyBlogInfo.desc
  },
  {
    id: 2,
    title: { PierceCountyBlogInfo }.PierceCountyBlogInfo.title,
    image: { PierceCountyBlogInfo }.PierceCountyBlogInfo.img,
    url: { PierceCountyBlogInfo }.PierceCountyBlogInfo.url,
    desc: { PierceCountyBlogInfo }.PierceCountyBlogInfo.desc
  }
]

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Blog</title>
      </Helmet>

      <div className="blog-image">
        <h1>Blog</h1>
      </div>

      <div id="blog-div">
        <BlogSummary stories={stories} />
      </div>
    </>
  );
};

export default Blog;