/**
 * Displays the more about my story
 *
 * @file   MoreAbout.jsx.
 * @author Jacob Summerville.
 * @since  02/20/2024
 */

import React from "react";

import "./../../styles/theme.scss";
import "./../../styles/about.scss";

const MoreAbout = () => {
  return (
    <div id="more-about">
      <h2>A Few More Things</h2>

      <p>
        As a Chicago native, I embraced the diverse culture that laid the foundation of my dynamic personality. my academic pursuits took me to the sun-kissed campus of the University of Arizona, where I graduated in 2022. After graduating, the call of the Pacific Northwest was irresistible, and thus, I found my new home in Washington, where the lush landscapes and innovative vibe resonate with my journey towards personal and professional fulfillment.
        <br/><br/>
        I'm a passionate software engineer, a snowboarding enthusiast, and a family person at heart. Married to my wonderful partner, we've embarked on the greatest adventure of all - parenthood, with our adorable baby boy lighting up our world.
        <br/><br/>
        When I'm not immersed in work or photogaphy, you can find me carving down snowy slopes, trekking through nature's trails, or watching my favorite football team: the Minnesota Vikings.
      </p>
    </div>
  );
};

export default MoreAbout;