/**
 * Displays the embedded pinterest boards
 *
 * @file   Pins.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React from "react";
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom';

import ReadMore from './../common/ReadMore.jsx'

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const PinBoards = (props) => {
  return (
    <div id="ideas-div">
      {
        props.themes.map((theme) =>
          <div className="idea-card" key={theme.id}>
            <div className="idea-card-text">
              <h2>{theme.title}</h2>
              <ReadMore expandable={true}>
                {theme.desc}
              </ReadMore>
            </div>

            <div className="pinterest-link">
              <Link to={theme.url}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  View on Pinterest
                  <ArrowRightIcon />
                </Stack>
              </Link>
            </div>

          </div>
        )
      }
    </div>
  );
};

export default PinBoards;