/**
 * Displays the contact page
 *
 * @file   Contact.jsx.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet'

import "./../styles/theme.scss";
import "./../styles/contact.scss";

const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const FORM_ENDPOINT = "https://public.herotofu.com/v1/cf543310-60bd-11ec-b63a-19467ff46d66";

const contactInit = {
  name: '',
  email: '',
  message: '',
  permission: 'yes'
}

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [submitComplete, setSubmitComplete] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [contactMsg, setContactMsg] = useState(contactInit);

  const updateContactMsg = (key, value) => {
    setContactMsg(prevMsg => ({
      ...prevMsg,
      [key]: value
    }));
  };

  const errorChecks = () => {
    if (contactMsg.name === '') {
      alert('Please tell me your name');
      return true
    }

    if (!emailRE.test(contactMsg.email.toLowerCase())) {
      alert('Your email seems to be formatted incorrectly');
      return true
    }

    if (contactMsg.message === '') {
      alert("Please tell me why you're contacting me");
      return true
    }

    return false;
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (errorChecks()) return;

    document.getElementById('progress-bar').style.display = 'block';

    if (!submitted) {
      setSubmitted(true);

      const response = await axios.post(
        FORM_ENDPOINT,
        contactMsg
      );

      if (response.status === 200) {
        setSubmitComplete(true);
        setSubmitted(false);
      } else {
        alert('Sorry! There was an error submitting your contact form.\n' +
          'If you keep having issues, you can reach out to me on Instagram');
        setSubmitted(false);
      }
    }
  };

  if (submitComplete) {
    return (
      <div id="contact-container">
        <h2 id="thank-you-header"> Thank you! </h2>
        <h4> I will be in touch soon </h4>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <div id="contact-container">
        <h1> Get in Touch </h1>

        <Box
          className="foreground-form"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >

          <h2 className="contact-section-header"> Tell me about yourself </h2>

          <div className="prompt-div">
            <TextField
              required
              sx={{ m: 1, width: '90%' }}
              id="name-input"
              label="Your name"
              defaultValue={contactMsg.name}
              onChange={({ target }) => updateContactMsg('name', target.value)}
            />
          </div>

          <div className="prompt-div">
            <TextField
              required
              error={contactMsg.email !== "" && !emailRE.test(contactMsg.email.toLowerCase())}
              helperText={(contactMsg.email !== "" && !emailRE.test(contactMsg.email.toLowerCase())) ? "Email incorrectly formatted" : ""}
              sx={{ m: 1, width: '90%' }}
              id="email-input"
              label="Your email address"
              defaultValue={contactMsg.email}
              onChange={({ target }) => updateContactMsg('email', target.value)}
            />
          </div>

          <div className="prompt-div">
            <TextField
              required
              sx={{ m: 1, width: '90%' }}
              id="message-input"
              label="Message"
              multiline
              maxRows={4}
              defaultValue={contactMsg.message}
              onChange={({ target }) => updateContactMsg('message', target.value)}
            />
          </div>

          <Divider id="contact-divider" />

          <div className="perm-div">
            <FormControl component="fieldset">
              <FormLabel component="legend">Would you allow me to post pictures I take to my online portfolio?</FormLabel>
              <RadioGroup
                row
                aria-label="permission"
                name="row-radio-buttons-group"
                id="permission-options"
                value={contactMsg.permission}
                onChange={({ target }) => updateContactMsg('permission', target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="button-div">
            <Button
              variant="contained"
              type="submit"
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </div>

          <div id="progress-bar">
            <CircularProgress />
          </div>

        </Box>
      </div>
    </>
  );
};

export default Contact;