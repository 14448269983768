/**
 * Displays the portrait events
 *
 * @file   Portraits.jsx.
 * @author Jacob Summerville.
 * @since  2/18/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import PortraitZoey from "./../../assets/portrait/2023-07-zoey/zoey1.webp"
import PortraitAshley from "./../../assets/portrait/2023-06-ashley/ashley1.webp"
import PortraitLauren from "./../../assets/portrait/2022-10-lauren/lauren1.webp"
import PortraitJenny from "./../../assets/portrait/2022-03-jenny/jenny1.webp"
import PortraitDamaris from "./../../assets/portrait/2022-02-damaris/damaris1.webp"
import PortraitJGMaternity from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby1.webp"
import PortraitRhyann from "./../../assets/portrait/2022-11-rhyann/rhyann1.webp"
import PortraitEnzio from "./../../assets/portrait/2024-02-enzio/enzio1.webp"
import PortraitLily from "./../../assets/portrait/2024-03-lily/lily1.webp"
import PortraitJeny from "./../../assets/portrait/2024-03-jeny/jeny1.webp"
import PortraitMisc from "./../../assets/portrait/misc/misc1.webp"

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

const Portraits = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Portrait Portfolio</title>
      </Helmet>

      <div className="portfolio-image">
        <h1>Portfolio</h1>
      </div>

      <div className="portfolio-div">
        <div className="portfolio-nav-div">
          <div className="portfolio-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/portfolio/portraits"}>Portraits</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/portfolio/nature"}>Nature</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <div id="portrait-events">
          <div>
            <Link to={"/portfolio/zoey-maddy-2023-07"}>
              <img src={PortraitZoey} alt="Nature portrait photoshoot at Wildwood Park in Puyallup, Washington." />
              <p className="portrait-model">Zoey & Maddy</p>
              <p className="portrait-type">Nature</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/damaris-2022-02"}>
              <img src={PortraitDamaris} alt="Nature photoshoot in Tucson, Arizona." />
              <p className="portrait-model">Damaris</p>
              <p className="portrait-type">Nature</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/lily-2024-03"}>
              <img src={PortraitLily} alt="Fashion portrait photoshoot at the University of Washington in Seattle, Washington." />
              <p className="portrait-model">Lily</p>
              <p className="portrait-type">Fashion</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/jeny-2024-03"}>
              <img src={PortraitJeny} alt="Fashion portrait photoshoot at the University of Washington in Seattle, Washington." />
              <p className="portrait-model">Jeny</p>
              <p className="portrait-type">Fashion</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/lauren-2022-10"}>
              <img src={PortraitLauren} alt="Nature/yoga portrait photoshoot in North Bend, Washington." />
              <p className="portrait-model">Lauren</p>
              <p className="portrait-type">Nature/Yoga</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/ashley-2023-06"}>
              <img src={PortraitAshley} alt="Sporty/athletic portrait photoshoot at Riverview Park in Renton, Washington." />
              <p className="portrait-model">Ashley</p>
              <p className="portrait-type">Sports</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/jake-gabby-2023-12"}>
              <img src={PortraitJGMaternity} alt="Maternity photoshoot in Bonney Lake, Washington." />
              <p className="portrait-model">Jake & Gabby</p>
              <p className="portrait-type">Maternity</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/jenny-2022-03"}>
              <img src={PortraitJenny} alt="Whimsical photoshoot in Tucson, Arizona." />
              <p className="portrait-model">Jenny</p>
              <p className="portrait-type">Whimsical</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/rhyann-2022-11"}>
              <img src={PortraitRhyann} alt="Nature photoshoot at Wildwood Park in Puyallup, Washington." />
              <p className="portrait-model">Rhyann</p>
              <p className="portrait-type">Nature</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/enzio-2024-02"}>
              <img src={PortraitEnzio} alt="Newborn photoshoot in Bonney Lake, Washington." />
              <p className="portrait-model">Enzio</p>
              <p className="portrait-type">Newborn</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/misc"}>
              <img src={PortraitMisc} alt="Miscellaneous pictures." />
              <p className="portrait-model">Miscellaneous</p>
              <p className="portrait-type"> </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portraits;