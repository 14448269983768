/**
 * Displays the nature events
 *
 * @file   Nature.jsx.
 * @author Jacob Summerville.
 * @since  2/24/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import Washington from "./../../assets/nature/washington/washington1.webp"
import Hawaii from "./../../assets/nature/hawaii/hawaii1.webp"
import Tennessee from "./../../assets/nature/tennessee/tennessee1.webp"
import Florida from "./../../assets/nature/florida/florida1.webp"
import Arizona from "./../../assets/nature/arizona/arizona1.webp"

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

const Nature = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Nature Portfolio</title>
      </Helmet>

      <div className="portfolio-image">
        <h1>Portfolio</h1>
      </div>

      <div className="portfolio-div">
        <div className="portfolio-nav-div">
          <div className="portfolio-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/portfolio/portraits"}>Portraits</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/portfolio/nature"}>Nature</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <div id="nature-events">
          <div>
            <Link to={"/portfolio/washington"}>
              <img src={Washington} alt="Nature photography in Washington" />
              <p className="portrait-model">Washington</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/hawaii"}>
              <img src={Hawaii} alt="Nature photography in Hawaii" />
              <p className="portrait-model">Hawaii</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/tennessee"}>
              <img src={Tennessee} alt="Nature photography in Tennessee" />
              <p className="portrait-model">Tennessee</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/florida"}>
              <img src={Florida} alt="Nature photography in Florida" />
              <p className="portrait-model">Florida</p>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/arizona"}>
              <img src={Arizona} alt="Nature photography in Arizona" />
              <p className="portrait-model">Arizona</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nature;