/**
 * Contains all nature photos
 *
 * @file   NatureImages.jsx.
 * @author Jacob Summerville.
 * @since  02/24/2024
 */

import React from "react";

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

import ImageGallery from "./ImageGallery";

import washington01 from "./../../assets/nature/washington/washington1.webp";
import washington02 from "./../../assets/nature/washington/washington2.webp";
import washington03 from "./../../assets/nature/washington/washington3.webp";
import washington04 from "./../../assets/nature/washington/washington4.webp";
import washington05 from "./../../assets/nature/washington/washington5.webp";

import florida01 from "./../../assets/nature/florida/florida1.webp";
import florida02 from "./../../assets/nature/florida/florida2.webp";
import florida03 from "./../../assets/nature/florida/florida3.webp";
import florida04 from "./../../assets/nature/florida/florida4.webp";

import tennessee01 from "./../../assets/nature/tennessee/tennessee1.webp";
import tennessee02 from "./../../assets/nature/tennessee/tennessee2.webp";
import tennessee03 from "./../../assets/nature/tennessee/tennessee3.webp";
import tennessee04 from "./../../assets/nature/tennessee/tennessee4.webp";
import tennessee05 from "./../../assets/nature/tennessee/tennessee5.webp";
import tennessee06 from "./../../assets/nature/tennessee/tennessee6.webp";

import arizona01 from "./../../assets/nature/arizona/arizona1.webp"
import arizona02 from "./../../assets/nature/arizona/arizona2.webp"

import hawaii01 from "./../../assets/nature/hawaii/hawaii1.webp"
import hawaii02 from "./../../assets/nature/hawaii/hawaii2.webp"
import hawaii03 from "./../../assets/nature/hawaii/hawaii3.webp"

/*-----------------------------------------------------------------------

/* Washington, USA */
const Washington = () => {
  const photos = [
    { src: washington01, href: washington01, width: 1000, height: 664, originalAlt: 'Washington photo' },
    { src: washington02, href: washington02, width: 667, height: 1000, originalAlt: 'Washington photo' },
    { src: washington03, href: washington03, width: 667, height: 1000, originalAlt: 'Washington photo' },
    { src: washington04, href: washington04, width: 1000, height: 667, originalAlt: 'Washington photo' },
    { src: washington05, href: washington05, width: 1000, height: 749, originalAlt: 'Washington photo' }
  ];

  return (
    <>
      <ImageGallery
        title="Washington"
        name="Washington, USA"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/nature"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Arizona, USA */
const Arizona = () => {
  const photos = [
    { src: arizona01, width: 1000, height: 666, originalAlt: 'Black and white capture of a desert cactus in Tucson, AZ' },
    { src: arizona02, width: 1000, height: 732, originalAlt: 'Photo of a desert cactus in Tucson, AZ' }
  ];

  return (
    <>
      <ImageGallery
        title="Arizona"
        name="Arizona, USA"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/nature"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Florida, USA */
const Florida = () => {
  const photos = [
    { src: florida01, width: 1000, height: 750, originalAlt: 'View of a boat in the Florida ocean during sunset' },
    { src: florida02, width: 1000, height: 750, originalAlt: 'Sailboat off the coast of Florida during sunset' },
    { src: florida03, width: 1000, height: 737, originalAlt: 'Lake mirrored image in Florida' },
    { src: florida04, width: 1000, height: 750, originalAlt: 'A sunset capture in Florida' }
  ];

  return (
    <>
      <ImageGallery
        title="Florida"
        name="Florida, USA"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/nature"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Tennessee, USA */
const Tennessee = () => {
  const photos = [
    { src: tennessee01, width: 1000, height: 667, originalAlt: 'Sunset over the Smoky Mountains in Galtinburg, TN' },
    { src: tennessee02, width: 1000, height: 667, originalAlt: 'Long exposure capture of a waterfall in Galtinburg, TN' },
    { src: tennessee03, width: 1000, height: 667, originalAlt: 'Long exposure capture of a waterfall in Galtinburg, TN' },
    { src: tennessee04, width: 1000, height: 667, originalAlt: 'Long exposure capture of a waterfall in Galtinburg, TN' },
    { src: tennessee05, width: 1000, height: 750, originalAlt: 'A beautiful waterfall capture in Galtinburg, TN' },
    { src: tennessee06, width: 1000, height: 667, originalAlt: 'A cabin in the the Smoky Mountain woods' }
  ];

  return (
    <>
      <ImageGallery
        title="Tennessee"
        name="Tennessee, USA"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/nature"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Hawaii, USA */
const Hawaii = () => {
  const photos = [
    { src: hawaii01, width: 1000, height: 667, originalAlt: 'A boat off the coast of Kauai, HI during sunset' },
    { src: hawaii02, width: 1000, height: 667, originalAlt: 'The coast of Kauai, HI during sunset' },
    { src: hawaii03, width: 1000, height: 750, originalAlt: 'A lighthouse on the coast of Kauai, HI' }
  ];

  return (
    <>
      <ImageGallery
        title="Hawaii"
        name="Hawaii, USA"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/nature"
      />
    </>
  );
};

export {
  Washington,
  Arizona,
  Florida,
  Tennessee,
  Hawaii
}