/**
 * Display text with a ReadMore button
 *
 * @file   ReadMore.jsx.
 * @author Jacob Summerville.
 * @since  02/26/2024
 */

import React, { useState } from "react";

import "./../../styles/theme.scss";
import "./../../styles/common.scss";

const ReadMore = (props) => {
  const text = props.children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  var textStartBreak = 120
  var textEndBreak = 160

  if (text.length > 150) {
    for (let i = textStartBreak; i < text.length; i++) {
      if (text[i] === ' ') {
        textStartBreak = i
        break
      }
    };

    for (let j = textEndBreak; j < text.length; j++) {
      if (text[j] === ' ') {
        textEndBreak = j
        break
      }
    };
  }

  if (text.length < textEndBreak) {
    return (
      <p className="read-more-text">
        {text}
      </p>
    );
  }
  if (props.expandable === true) {
    return (
      <p className="read-more-text">
        {isReadMore ? text.slice(0, textStartBreak) : text}

        <span
          className="next-text"
        >
          {isReadMore ? text.slice(textStartBreak, textEndBreak) : ""}
        </span>

        <span
          onClick={toggleReadMore}
          className="read-or-hide"
        >
          {isReadMore ? " ... read more" : " show less"}
        </span>
      </p>
    );
  } else {
    return (
      <p className="read-more-text">
        {text.slice(0, textStartBreak)}

        <span
          className="next-text"
        >
          {text.slice(textStartBreak, textEndBreak)}
        </span>

        ...
      </p>
    );
  }


};

export default ReadMore;