/**
 * This file is the entry point to this website.
 *
 * @file   index.js.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App.jsx';

import './styles/theme.scss'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);

// const rootElement = document.getElementById("root");

// ReactDOM.render(
//   <App />,
//   rootElement
// );
