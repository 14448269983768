/**
 * Displays the contact box on the home page
 *
 * @file   Contact-Box.jsx.
 * @author Jacob Summerville.
 * @since  02/17/2024
 */

import React from "react";
import { Link } from 'react-router-dom';

import "./../../styles/theme.scss";
import "./../../styles/home.scss";

const ContactBox = () => {
  return (
    <div id="home-contact-div">
      <div className="home-contact-column" id="contact-col-a">
        <p>Portrait photographer based in <br/> Bonney Lake, Washington</p>
        <p id="contact-dates">Currently booking for 2024</p>
      </div>
      <div className="home-contact-column" id="contact-col-b">
        <div className="home-contact-link">
          <Link to={"/contact"}>Contact</Link>
        </div>
      </div>
    </div>
  );
};

export default ContactBox;