/**
 * Displays the Ideas page with active themes
 *
 * @file   IdeasActive.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import PinBoards from "./PinBoards.jsx";

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const pinURL = "https://www.pinterest.com/jsummerphotos"

const themes = [
  { id: 1, title: "Hiking", url: pinURL + "/active-themes/hiking/", desc: "Hiking portrait photography captures the essence of exploration and the human spirit amidst the grandeur of nature. It's about framing the subject in their element, often with a backdrop of sweeping landscapes, rugged terrains, or serene trails." },
  { id: 2, title: "Running", url: pinURL + "/active-themes/running/", desc: "Running portrait photography is a dynamic and challenging genre that captures the essence of movement and athleticism. It involves photographing runners in action, often during races or in motion, to create powerful and inspiring images." },
  { id: 3, title: "Snowboarding/Skiing", url: pinURL + "/active-themes/snowboardingskiing/", desc: "Capturing the essence of snowboarding in portrait photography involves highlighting the dynamic contrast between the subject and the vast, wintry landscape." },
  { id: 4, title: "Workout", url: pinURL + "/active-themes/workout/", desc: "Workout portrait photography captures the intensity, focus, and dedication of individuals engaged in physical activity. It's about freezing a moment that showcases the strength and endurance of the human body." },
  { id: 5, title: "Yoga", url: pinURL + "/active-themes/yoga/", desc: "Yoga portrait photography captures the essence of one's yoga practice, combining the art of photography with the grace and tranquility of yoga poses." }
]

const IdeasActive = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Active Themes</title>
      </Helmet>

      <div className="ideas-image">
        <h1>Ideas</h1>
      </div>

      <div className="ideas-div">
        <div className="ideas-nav-div">
          <div className="ideas-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/general"}>General</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/mood"}>Mood</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/ideas/active"}>Active</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/seasonal"}>Seasonal</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <PinBoards themes={themes} />
      </div>
    </>
  );
};

export default IdeasActive;