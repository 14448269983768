/**
 * Displays my story
 *
 * @file   Story.jsx.
 * @author Jacob Summerville.
 * @since  01/02/2022
 */

import React from "react";

import LocationCityIcon from '@mui/icons-material/LocationCity';
import WorkIcon from '@mui/icons-material/Work';

import "./../../styles/theme.scss";
import "./../../styles/about.scss";

const Location = () => {
  return (
    <div id="story-div">
      <h2>Jacob Summerville</h2>
      <h3>Photographer</h3>

      <p>
        As a child, I was drawn to the wonders of nature. The rustling leaves, the vibrant flowers, and the play of light on dew-kissed grass fascinated me. Armed with a simple point-and-shoot camera, I embarked on my photographic adventure. Those early days were filled with curiosity and experimentation. I captured sunsets, dewdrops, and the intricate patterns of leaves. Each click was a magical moment frozen in time.
        <br/><br/>
        Nature photography became my muse. I reveled in the challenge of capturing the elusive beauty of a butterfly perched on a petal or the grandeur of a snow-capped mountain. Armed with patience and a keen eye, I explored forests, meadows, and mountaintops. My lens became a bridge between the ordinary and the extraordinary.
        <br/><br/>
        But life evolves, and so did my passion. A few years ago, I stepped into the realm of portrait photography. Faces became my canvas, emotions my palette. I discovered the art of storytelling through expressions—the laughter lines etched by joy, the furrowed brows of contemplation, and the sparkle in someone's eyes. Portraits allowed me to connect with people on a deeper level, to unravel their stories one frame at a time.
        <br/><br/>
        My trusty camera evolved too. From that initial point-and-shoot, I graduated to a DSLR — a faithful companion on my photographic journey. Lenses became my magic wands. The versatility of a zoom lens and the intimacy of a prime lens—they both found a place in my bag. And let's not forget the tripod—the unsung hero that steadied my shots during golden hours and moonlit escapades.
        <br/><br/>
        Photography isn't just about clicking buttons; it's about seeing the world differently. It's about chasing sunsets, waiting for that perfect moment, and feeling the heartbeat of life through the viewfinder. So, here I am—a perpetual student of light, a seeker of stories, and a lover of pixels. My journey continues, frame by frame, as I capture the essence of existence—one click at a time.
      </p>

      <div id="about-cards">
        <div className="about-column" id="about-a">
          <LocationCityIcon style={{ fontSize: 40 }} />
          <h4> Location </h4>
          <p id="locationP"> Bonney Lake, WA </p>
        </div>
        <div className="about-column" id="about-c">
          <WorkIcon style={{ fontSize: 40 }} />
          <h4> Experience </h4>
          <p id="expP">10+ Years</p>
        </div>
      </div>
    </div>
  );
};

export default Location;