/**
 * Displays the blog summaries
 *
 * @file   BlogSummary.jsx.
 * @author Jacob Summerville.
 * @since  02/26/2024
 */

import React from "react";
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom';

import ReadMore from "./../common/ReadMore.jsx";

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const BlogSummary = (props) => {

  return (
    <div id="blog-div">
      {
        props.stories.map((story) =>
          <div className="blog-card" key={story.id}>
            <img src={story.image} alt="" />

            <div className="blog-card-text">
              <h2>{story.title}</h2>
              <ReadMore expandable={false}>
                {story.desc}
              </ReadMore>
            </div>

            <div className="blog-link">
              <Link to={story.url}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  Read More
                  <ArrowRightIcon />
                </Stack>
              </Link>
            </div>

          </div>
        )
      }
    </div>
  );
};

export default BlogSummary;