/**
 * Displays the home page quote
 *
 * @file   Quote.jsx.
 * @author Jacob Summerville.
 * @since  02/17/2024
 */

import React from "react";

import "./../../styles/theme.scss";
import "./../../styles/home.scss";

const Quote = () => {
  return (
    <div id="home-quote">
      <p>
        "You don't make a photograph just with a camera. You bring to the act of photography all the pictures you have seen, the books you have read, the music you have heard, the people you have loved." - Ansel Adams
      </p>
    </div>
  );
};

export default Quote;