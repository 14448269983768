/**
 * Displays the Ideas page with general themes
 *
 * @file   IdeasGeneral.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import PinBoards from "./PinBoards.jsx";

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const pinURL = "https://www.pinterest.com/jsummerphotos"

const themes = [
  { id: 1, title: "Beach", url: pinURL + "/general-themes/beach/", desc: "Beach portrait photography captures the essence of serenity and the timeless beauty of the sea. It's about framing subjects against the vast canvas of the water, the soft hues of the sky, and the natural patterns in the sand." },
  { id: 2, title: "Bohemian", url: pinURL + "/general-themes/bohemian", desc: "Bohemian portrait photography captures the free-spirited, artistic essence of its subjects, often characterized by a fusion of vintage and ethnic styles. It emphasizes natural beauty and individuality, typically featuring relaxed poses, candid expressions, and a harmonious blend with nature or eclectic interiors." },
  { id: 3, title: "Cars", url: pinURL + "/general-themes/cars", desc: "Car portrait photography captures the essence and design of a vehicle, emphasizing its unique features and aesthetic appeal. It's about finding the right angle, lighting, and backdrop to complement the car's lines and curves." },
  { id: 4, title: "Closeup", url: pinURL + "/general-themes/closeup", desc: "Close-up portrait photography is a style that captures the intricate details and expressions of a subject's face from a close range. It emphasizes the features and emotions, often focusing on the eyes, which are said to be the windows to the soul." },
  { id: 5, title: "Creative", url: pinURL + "/general-themes/creative", desc: "Creative portrait photography is an art form that blends classic portraiture with imaginative expression. It's about capturing the essence of a subject while infusing the image with a sense of originality and innovation." },
  { id: 6, title: "Edgy", url: pinURL + "/general-themes/edgy", desc: "Edgy portrait photography captures the subject in a bold, unconventional manner, often characterized by high contrast, dramatic lighting, and intense expressions. It's about breaking the traditional rules of composition and lighting to create a striking image that commands attention." },
  { id: 7, title: "Fantasy", url: pinURL + "/general-themes/fantasy", desc: "Fantasy portrait photography is a creative genre that blends the magic of fantasy worlds with the art of portraiture. It often involves elaborate costumes, unique settings, and sometimes digital enhancements to transport the subject into a mythical and otherworldly realm." },
  { id: 8, title: "Glamour", url: pinURL + "/general-themes/glamour", desc: "Glamour portrait photography is a genre that focuses on highlighting the subject's beauty and allure. It often involves careful lighting, makeup, and styling to create an image that is both flattering and artistically appealing." },
  { id: 9, title: "Indoor", url: pinURL + "/general-themes/indoor", desc: "Indoor portrait photography focuses on capturing the personality and mood of a subject within an interior setting. This genre of photography often utilizes controlled lighting setups, backdrops, and props to enhance the composition and highlight the subject's features." },
  { id: 10, title: "Minimalist", url: pinURL + "/general-themes/minimalist", desc: "Minimalist portrait photography focuses on simplicity and the deliberate use of compositional elements to highlight the subject. It often involves a clean, uncluttered background and a composition that draws the viewer's attention directly to the subject's features or expressions." },
  { id: 11, title: "Nature", url: pinURL + "/general-themes/nature", desc: "Nature portrait photography captures the essence of the outdoors, intertwining human elements with the raw beauty of the natural world. It's a harmonious blend where the subject becomes part of the landscape, rather than the focal point." },
  { id: 12, title: "Professional", url: pinURL + "/general-themes/professional", desc: "Professional portrait photography captures the professional and approachable side of an individual or a team. These portraits are often used for company websites, LinkedIn profiles, press releases, and other marketing materials." },
  { id: 13, title: "Rainy", url: pinURL + "/general-themes/rainy", desc: "Rainy portrait photography captures the essence of a subject amidst the serene and introspective ambiance of a rainy day. The soft, diffused light of an overcast sky acts as a natural softbox, enveloping the subject in a gentle glow." },
  { id: 14, title: "Urban", url: pinURL + "/general-themes/urban", desc: "Urban portrait photography is a vibrant and dynamic genre that captures the essence of city life through the lens of portraiture. It combines the raw, candid moments of street photography with the focused, intentional composition of traditional portraiture." }
]

const Ideas = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>General Themes</title>
      </Helmet>

      <div className="ideas-image">
        <h1>Ideas</h1>
      </div>

      <div className="ideas-div">
        <div className="ideas-nav-div">
          <div className="ideas-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/ideas/general"}>General</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/mood"}>Mood</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/active"}>Active</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/seasonal"}>Seasonal</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <PinBoards themes={themes} />
      </div>
    </>
  );
};

export default Ideas;