/**
 * Displays the 404 NotFound page
 *
 * @file   NotFound.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from "react";
import { Helmet } from 'react-helmet'

import NotFoundImg from "./../assets/img/not-found.webp"

import "./../styles/theme.scss";
import "./../styles/blog.scss";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>JSPhotos</title>
      </Helmet>

      <div id="not-found">
        <img src={NotFoundImg} alt="404 page not found" />
      </div>
    </>
  );
};

export default NotFound;