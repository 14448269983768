/**
 * Displays the navigation bar on the bottom of the page
 *
 * @file   Footer.jsx.
 * @author Jacob Summerville.
 * @since  2/18/2024
 */

import React from "react";
import { Link } from 'react-router-dom';

import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';

import "./../../styles/footer.scss";
import "./../../styles/theme.scss";

const socialIconStyle = {
  fill: '#5b5861',
  width: '25px'
};

const Footer = () => {

  return (
    <section className="footer">
      <div className="footer-nav-div">
        <nav className="footer-nav footer-left">
          <ul className="footer-list-left">
            <li><Link to={"/"}>Home</Link></li>
            <li><Link to={"/portfolio"}>Portfolio</Link></li>
            <li><Link to={"/ideas/general"}>Ideas</Link></li>
          </ul>
        </nav>

        <div className="footer-logo">
          <Link to={"/"}>Jacob Summerville</Link>
        </div>

        <nav className="footer-nav footer-right">
          <ul className="footer-list-right">
            <li><Link to={"/about"}>About</Link></li>
            <li><Link to={"/blog"}>Blog</Link></li>
            <li><Link to={"/contact"}>Contact</Link></li>
          </ul>
        </nav>
      </div>
      <div className="footer-social-div">
        <a
          className="socialLink"
          target="_blank"
          href="https://www.instagram.com/jsummerphotos/"
          rel="noopener noreferrer"
        >
          <InstagramIcon style={socialIconStyle}/>
        </a>

        <a
          className="socialLink"
          target="_blank"
          href="https://www.instagram.com/jsphotos_nature/"
          rel="noopener noreferrer"
        >
          <InstagramIcon style={socialIconStyle}/>
        </a>

        <a
          className="socialLink"
          target="_blank"
          href="https://www.pinterest.com/jsummerphotos/"
          rel="noopener noreferrer"
        >
          <PinterestIcon style={socialIconStyle}/>
        </a>

        <a
          className="socialLink"
          target="_blank"
          href="https://twitter.com/jsummerphotos/"
          rel="noopener noreferrer"
        >
          <TwitterIcon style={socialIconStyle}/>
        </a>

      </div>
    </section>
  );
};

export default Footer;