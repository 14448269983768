/**
 * Displays the self portraits on the about me page
 *
 * @file   SelfPortraits.jsx.
 * @author Jacob Summerville.
 * @since  02/19/2024
 */

import React from "react";

import AboutImg1 from "./../../assets/img/about-1.webp"
import AboutImg2 from "./../../assets/img/about-2.webp"
import AboutImg3 from "./../../assets/img/about-3.webp"

import "./../../styles/theme.scss";
import "./../../styles/home.scss";

const SelfPortraits = () => {
  return (
    <div id="self-portraits-div">
      <div>
        <img src={AboutImg1} alt="Self portrait" />
      </div>
      <div>
        <img src={AboutImg2} alt="Family portrait" />
      </div>
      <div>
        <img src={AboutImg3} alt="graduation portrait" />
      </div>
    </div>
  );
};

export default SelfPortraits;