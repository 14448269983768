/**
 * Displays the navigation bar on the top of the page
 *
 * @file   Header.jsx.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React, { useEffect } from "react";

import DesktopNav from './DesktopNav.jsx'
import MobileNav from './MobileNav.jsx'

import "./../../styles/header.scss";
import "./../../styles/theme.scss";

const Header = () => {

  const [windowWidth, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 800;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [])

  if (windowWidth < breakpoint) {
    return (
      <MobileNav />
    )
  } else {
    return (
      <DesktopNav />
    )
  }

};

export default Header;