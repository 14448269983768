/**
 * The main controller for page redirection
 *
 * @file   App.jsx.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home.jsx';
import About from './pages/About.jsx';
import InWork from './pages/InWork.jsx';
import Contact from './pages/Contact.jsx';
import Questions from './pages/Questions.jsx';
import Survey from './pages/Survey.jsx';
import NotFound from './pages/NotFound.jsx';

import IdeasGeneral from './components/ideas/IdeasGeneral.jsx';
import IdeasMood from './components/ideas/IdeasMood.jsx';
import IdeasActive from './components/ideas/IdeasActive.jsx';
import IdeasSeasonal from './components/ideas/IdeasSeasonal.jsx';

import Portraits from './components/portfolio/Portraits.jsx';
import Nature from './components/portfolio/Nature.jsx';

import Blog from './pages/Blog.jsx';
import KingCountyLocations from './components/blog/KingCountyLocations.jsx';
import PierceCountyLocations from './components/blog/PierceCountyLocations.jsx';

import {
  Zoey202307, Ashley202306, Lauren202210, JakeGabby202312,
  Jenny202203, Damaris202202, Rhyann202211, Enzio202402,
  Lily202403, Jeny202403, Misc
} from './components/portfolio/PortraitImages.jsx';

import {
  Washington, Arizona, Florida, Tennessee, Hawaii
} from './components/portfolio/NatureImages.jsx';

import Header from './components/common/Header.jsx';
import Footer from './components/common/Footer.jsx';

const App = () => {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/survey" element={<Survey />} />

          <Route path="/portfolio" element={<Navigate to="/portfolio/portraits" />} />
          <Route path="/portfolio/portraits" element={<Portraits />} />
          <Route path="/portfolio/nature" element={<Nature />} />

          <Route path="/ideas" element={<Navigate to="/ideas/general" />} />
          <Route path="/ideas/general" element={<IdeasGeneral />} />
          <Route path="/ideas/mood" element={<IdeasMood />} />
          <Route path="/ideas/active" element={<IdeasActive />} />
          <Route path="/ideas/seasonal" element={<IdeasSeasonal />} />

          <Route path="/portfolio/jeny-2024-03" element={<Jeny202403 />} />
          <Route path="/portfolio/lily-2024-03" element={<Lily202403 />} />
          <Route path="/portfolio/enzio-2024-02" element={<Enzio202402 />} />
          <Route path="/portfolio/jake-gabby-2023-12" element={<JakeGabby202312 />} />
          <Route path="/portfolio/zoey-maddy-2023-07" element={<Zoey202307 />} />
          <Route path="/portfolio/ashley-2023-06" element={<Ashley202306 />} />
          <Route path="/portfolio/rhyann-2022-11" element={<Rhyann202211 />} />
          <Route path="/portfolio/lauren-2022-10" element={<Lauren202210 />} />
          <Route path="/portfolio/jenny-2022-03" element={<Jenny202203 />} />
          <Route path="/portfolio/damaris-2022-02" element={<Damaris202202 />} />
          <Route path="/portfolio/misc" element={<Misc />} />

          <Route path="/portfolio/washington" element={<Washington />} />
          <Route path="/portfolio/hawaii" element={<Hawaii />} />
          <Route path="/portfolio/tennessee" element={<Tennessee />} />
          <Route path="/portfolio/florida" element={<Florida />} />
          <Route path="/portfolio/arizona" element={<Arizona />} />

          <Route path="/blog" element={<InWork />} />
          <Route path="/blog/inwork" element={<Blog />} />
          <Route path="/blog/king-county-photoshoot-locations" element={<KingCountyLocations />} />
          <Route path="/blog/pierce-county-photoshoot-locations" element={<PierceCountyLocations />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;