/**
 * Displays an embedded map
 *
 * @file   Map.jsx.
 * @author Jacob Summerville.
 * @since  02/27/2024
 */

import React from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import "./../../styles/theme.scss";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = (props) => {

  const handleMarkerClick = (url) => {
    window.open(url, "_blank");
  }

  return (
    <MapContainer center={props.position} zoom={13} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={props.position}
        eventHandlers={{
          click: (e) => {
            handleMarkerClick(props.urlLink)
          },
        }}>
      </Marker>
    </MapContainer>
  );
};

export default Map;