/**
 * Displays the questions page
 *
 * @file   Questions.jsx.
 * @author Jacob Summerville.
 * @since  02/27/2024
 */

import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Helmet } from 'react-helmet'

import "./../styles/theme.scss";
import "./../styles/questions.scss";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/cf543310-60bd-11ec-b63a-19467ff46d66";

const contactInit = {
  name: '',
  email: '',
  phone: '',
  instagram: '',
  preferred_contact: '',
  experience: '',
  hopes: '',
  must_haves: '',
  editing: '',
  better_side: '',
  focuses: '',
  no_focuses: '',
  uncomforts: '',
  make_great: '',
  auth: 'Pictures_and_Videos',
  insta_connect: '',
  etc : ''
}

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [submitComplete, setSubmitComplete] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [contactMsg, setContactMsg] = useState(contactInit);

  const updateContactMsg = (key, value) => {
    setContactMsg(prevMsg => ({
      ...prevMsg,
      [key]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    document.getElementById('question-progress-bar').style.display = 'block';

    if (!submitted) {
      setSubmitted(true);

      const response = await axios.post(
        FORM_ENDPOINT,
        contactMsg
      );

      if (response.status === 200) {
        setSubmitComplete(true);
        setSubmitted(false);
      } else {
        alert('Sorry! There was an error submitting your questionnaire form.');
        setSubmitted(false);
      }
    }
  };

  if (submitComplete) {
    return (
      <div id="question-form-submitted">
        <h2> Questionnaire submitted! </h2>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Questionnaire</title>
      </Helmet>

      <div id="questions-div">
        <h1> Model Questionnaire </h1>

        <p id="question-description">
          This questionnaire is intended to help me get a better idea of who you are and what you expect from a photoshoot. All questions are optional so feel free to only answer the questions you are comfortable answering.
        </p>

        <Box
          id="question-form"
          component="form"
          onSubmit={handleSubmit}
        >

          <div className="question-prompt">
            <label>Name</label><br />
            <input
              type="text"
              value={contactMsg.name}
              onChange={({ target }) => updateContactMsg('name', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Email</label><br />
            <input
              type="text"
              value={contactMsg.email}
              onChange={({ target }) => updateContactMsg('email', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Phone</label><br />
            <input
              type="text"
              value={contactMsg.phone}
              onChange={({ target }) => updateContactMsg('phone', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Instagram</label><br />
            <input
              type="text"
              value={contactMsg.instagram}
              onChange={({ target }) => updateContactMsg('instagram', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Preferred Contact Method</label><br />
            <input
              type="text"
              value={contactMsg.preferred_contact}
              onChange={({ target }) => updateContactMsg('preferred_contact', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>How long have you been modeling?</label><br />
            <input
              type="text"
              value={contactMsg.experience}
              onChange={({ target }) => updateContactMsg('experience', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>What do you hope to get out of this photoshoot?</label><br />
            <input
              type="text"
              value={contactMsg.hopes}
              onChange={({ target }) => updateContactMsg('hopes', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Are there any must have shots that you want?</label><br />
            <input
              type="text"
              value={contactMsg.must_haves}
              onChange={({ target }) => updateContactMsg('must_haves', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>How much editing do you want to be done on the pictures?</label><br />
            <input
              type="text"
              value={contactMsg.editing}
              onChange={({ target }) => updateContactMsg('editing', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Do you have a better side (left vs right)?</label><br />
            <input
              type="text"
              value={contactMsg.better_side}
              onChange={({ target }) => updateContactMsg('better_side', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Do you have any physical features that you want to focus on (eyes, tattoos, etc.)?</label><br />
            <input
              type="text"
              value={contactMsg.focuses}
              onChange={({ target }) => updateContactMsg('focuses', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Do you have any physical features that you don't want to focus on?</label><br />
            <input
              type="text"
              value={contactMsg.no_focuses}
              onChange={({ target }) => updateContactMsg('no_focuses', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anything that makes you uncomfortable during a photoshoot?</label><br />
            <input
              type="text"
              value={contactMsg.uncomforts}
              onChange={({ target }) => updateContactMsg('uncomforts', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anything that I can do to help make the photoshoot great?</label><br />
            <input
              type="text"
              value={contactMsg.make_great}
              onChange={({ target }) => updateContactMsg('make_great', target.value)}
            />
          </div>

          <div className="question-radial-div">
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{ fontSize: "0.85em", color: "#626262" }}
              >
                Do you give me permission to post pictures and videos of you to my online portfolio and social media platforms?
              </FormLabel>
              <RadioGroup
                row
                className="question-radial-options"
                value={contactMsg.auth}
                onChange={({ target }) => updateContactMsg('auth', target.value)}
              >
                <FormControlLabel
                  value="Pictures_and_Videos"
                  control={<Radio />}
                  label={<span style={{ fontSize: '0.9rem', color: "#373737" }}>Pictures and Videos</span>}
                  defaultChecked
                />
                <FormControlLabel
                  value="Pictures"
                  control={<Radio />}
                  label={<span style={{ fontSize: '0.9rem', color: "#373737" }}>Pictures Only</span>}
                />
                <FormControlLabel
                  value="Neither"
                  control={<Radio />}
                  label={<span style={{ fontSize: '0.9rem', color: "#373737" }}>Neither</span>}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="question-prompt">
            <label>If I posted a picture of you to Instagram, would you rather be a collaborator, tagged, or not mentioned?</label><br />
            <input
              type="text"
              value={contactMsg.insta_connect}
              onChange={({ target }) => updateContactMsg('insta_connect', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anything else that you would like to mention?</label><br />
            <input
              type="text"
              value={contactMsg.etc}
              onChange={({ target }) => updateContactMsg('etc', target.value)}
            />
          </div>

          <div className="question-button-div">
            <Button
              sx={{ color: '#403939', border: "1px solid #403939" }}
              variant="outlined"
              type="submit"
              endIcon={<SendIcon />}
            >
              Submit
            </Button>
          </div>

          <div id="question-progress-bar">
            <CircularProgress />
          </div>

        </Box>
      </div>
    </>
  );
};

export default Blog;