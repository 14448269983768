/**
 * Displays the about page
 *
 * @file   About.jsx.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React, {useEffect} from "react";
import { Helmet } from 'react-helmet'

import Story from './../components/about/Story.jsx'
import MoreAbout from './../components/about/MoreAbout.jsx'
import SelfPortraits from './../components/about/SelfPortraits.jsx'

import "./../styles/theme.scss";
import "./../styles/about.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>

      <div id="about-image">
        <h1>About Me</h1>
      </div>

      <Story />
      <MoreAbout />
      <SelfPortraits />
    </>
  );
};

export default About;