/**
 * Displays the home page
 *
 * @file   Home.jsx.
 * @author Jacob Summerville.
 * @since  12/14/2021
 */

import React, {useEffect} from "react";
import { Helmet } from 'react-helmet'

import Description from './../components/home/Description.jsx'
import Focus from './../components/home/Focus.jsx'
import ContactBox from './../components/home/Contact-Box.jsx'
import Quote from './../components/home/Quote.jsx'

import "./../styles/theme.scss";
import "./../styles/home.scss";

const siteDesc = 'A Washington State photographer that is local to Bonney Lake.' +
                 'I specialize in portrait photography, family photography, and ' +
                 'nature photography.'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content={siteDesc} />
      </Helmet>

      <div className="header-image"></div>

      <Description />
      <Focus />
      <Quote />
      <ContactBox />
    </>
  );
};

export default Home;