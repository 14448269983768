/**
 * Displays the Ideas page with seasonal themes
 *
 * @file   IdeasSeasonal.jsx.
 * @author Jacob Summerville.
 * @since  02/25/2024
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import PinBoards from "./PinBoards.jsx";

import "./../../styles/theme.scss";
import "./../../styles/ideas.scss";

const pinURL = "https://www.pinterest.com/jsummerphotos"

const themes = [
  { id: 1, title: "Christmas", url: pinURL + "/seasonal-themes/christmas/", desc: "Christmas portrait photography captures the joy and warmth of festive seasons, creating cherished memories with loved ones." },
  { id: 2, title: "Fall", url: pinURL + "/seasonal-themes/fall/", desc: "Fall portrait photography captures the essence of the autumn season, with its warm, golden hues and the soft, diffused light that bathes the landscape." },
  { id: 3, title: "Halloween", url: pinURL + "/seasonal-themes/halloween/", desc: "Halloween portrait photography is a creative and thematic form of portraiture that captures the essence of Halloween. It often involves subjects dressed in costumes, ranging from the spooky to the whimsical, set against a backdrop that complements the Halloween theme." },
  { id: 4, title: "Spring", url: pinURL + "/seasonal-themes/spring/", desc: "Spring portrait photography captures the essence of the season through images that reflect its vibrant colors, fresh blooms, and the sense of renewal that spring brings." },
  { id: 5, title: "Valentine", url: pinURL + "/seasonal-themes/valentine/", desc: "Valentine portrait photography is a genre that captures the essence of romance and affection, often in celebration of Valentine's Day. It focuses on creating images that reflect love, intimacy, and the connection between individuals." },
  { id: 6, title: "Winter", url: pinURL + "/seasonal-themes/winter/", desc: "Winter portrait photography is a unique genre that captures the essence of the season through the lens of a camera. It involves taking photographs of individuals or groups against the backdrop of winter's natural beauty." }
]

const IdeasSeasonal = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Seasonal Themes</title>
        <script type="text/javascript" async defer src="//assets.pinterest.com/js/pinit.js"></script>
      </Helmet>

      <div className="ideas-image">
        <h1>Ideas</h1>
      </div>

      <div className="ideas-div">
        <div className="ideas-nav-div">
          <div className="ideas-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/general"}>General</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/mood"}>Mood</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/ideas/active"}>Active</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/ideas/seasonal"}>Seasonal</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <PinBoards themes={themes} />
      </div>
    </>
  );
};

export default IdeasSeasonal;