/**
 * Displays the survet page
 *
 * @file   Survey.jsx.
 * @author Jacob Summerville.
 * @since  03/01/2024
 */

import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import SendIcon from '@mui/icons-material/Send';
import { Helmet } from 'react-helmet'

import "./../styles/theme.scss";
import "./../styles/questions.scss";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/cf543310-60bd-11ec-b63a-19467ff46d66";

const surveyInit = {
  comms_before: 5,
  comms_during: 5,
  comms_after: 5,
  number_photos: 5,
  quality_photos: 5,
  timeliness: 5,
  help_confuse_concern: '',
  improvements: '',
  enjoyed: '',
  better_photos: '',
  public_review: '',
  comments: '',
  future_shoot: '',
  recommended: ''
}

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [submitComplete, setSubmitComplete] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [surveyMsg, setSurvetMsg] = useState(surveyInit);

  const updateSurveyMsg = (key, value) => {
    setSurvetMsg(prevMsg => ({
      ...prevMsg,
      [key]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    document.getElementById('question-progress-bar').style.display = 'block';

    if (!submitted) {
      setSubmitted(true);

      const response = await axios.post(
        FORM_ENDPOINT,
        surveyMsg
      );

      if (response.status === 200) {
        setSubmitComplete(true);
        setSubmitted(false);
      } else {
        alert('Sorry! There was an error submitting your survey form.');
        setSubmitted(false);
      }
    }
  };

  if (submitComplete) {
    return (
      <div id="question-form-submitted">
        <h2> Survey submitted! </h2>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey</title>
      </Helmet>

      <div id="questions-div">
        <h1> Survey </h1>

        <p id="question-description">
          This survey is intended to help me get a better idea of how the photoshoot went and how I can improve. Any feedback is appreciated. Thanks!
        </p>

        <Box
          id="question-form"
          component="form"
          onSubmit={handleSubmit}
        >

          <p>Provide a 1-10 rating for each of the following</p>

          <div className="slider-prompt">
            <label>Communication before the photoshoot</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.comms_before}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('comms_before', target.value)}
              />
            </div>
          </div>

          <div className="slider-prompt">
            <label>Communication during the photoshoot</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.comms_during}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('comms_during', target.value)}
              />
            </div>
          </div>

          <div className="slider-prompt">
            <label>Communication after the photoshoot</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.comms_after}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('comms_after', target.value)}
              />
            </div>
          </div>

          <div className="slider-prompt">
            <label>Number of photos</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.number_photos}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('number_photos', target.value)}
              />
            </div>
          </div>

          <div className="slider-prompt">
            <label>Quality of photos</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.quality_photos}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('quality_photos', target.value)}
              />
            </div>
          </div>

          <div className="slider-prompt">
            <label>Timelineness</label><br />
            <div>
              <Slider
                aria-label="Small steps"
                defaultValue={5}
                value={surveyMsg.timeliness}
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={({ target }) => updateSurveyMsg('timeliness', target.value)}
              />
            </div>
          </div>

          <Divider sx={{margin: "50px 0"}}/>

          <p>Feedback questions</p>

          <div className="question-prompt">
            <label>Did I do anything that was helpful, confusing, or concerning?</label><br />
            <input
              type="text"
              value={surveyMsg.help_confuse_concern}
              onChange={({ target }) => updateSurveyMsg('help_confuse_concern', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>What could have made the experience better?</label><br />
            <input
              type="text"
              value={surveyMsg.improvements}
              onChange={({ target }) => updateSurveyMsg('improvements', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>What did you enjoy about the experience?</label><br />
            <input
              type="text"
              value={surveyMsg.enjoyed}
              onChange={({ target }) => updateSurveyMsg('enjoyed', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>What would have made the photos better?</label><br />
            <input
              type="text"
              value={surveyMsg.better_photos}
              onChange={({ target }) => updateSurveyMsg('better_photos', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anything that you would like to share publicly with others?</label><br />
            <input
              type="text"
              value={surveyMsg.public_review}
              onChange={({ target }) => updateSurveyMsg('public_review', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anything else that you would like to mention?</label><br />
            <input
              type="text"
              value={surveyMsg.comments}
              onChange={({ target }) => updateSurveyMsg('comments', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Would you like to do another photoshoot with me in the future?</label><br />
            <input
              type="text"
              value={surveyMsg.future_shoot}
              onChange={({ target }) => updateSurveyMsg('future_shoot', target.value)}
            />
          </div>

          <div className="question-prompt">
            <label>Is there anyone that you would like to recommend for a future photoshoot?</label><br />
            <input
              type="text"
              value={surveyMsg.recommended}
              onChange={({ target }) => updateSurveyMsg('recommended', target.value)}
            />
          </div>

          <div className="question-button-div">
            <Button
              sx={{ color: '#403939', border: "1px solid #403939" }}
              variant="outlined"
              type="submit"
              endIcon={<SendIcon />}
            >
              Submit
            </Button>
          </div>

          <div id="question-progress-bar">
            <CircularProgress />
          </div>

        </Box>
      </div>
    </>
  );
};

export default Blog;