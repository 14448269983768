/**
 * Displays the King county photo location blog page
 *
 * @file   KingCountyLocations.jsx.
 * @author Jacob Summerville.
 * @since  02/26/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./../../styles/theme.scss";
import "./../../styles/blog.scss";

import StoryImg from "./../../assets/blog/1-king-county/great-wheel.webp"
import LakeSammImg1 from "./../../assets/blog/1-king-county/lake-sammamish1.webp"
import LakeSammImg2 from "./../../assets/blog/1-king-county/lake-sammamish2.webp"
import LakeSammImg3 from "./../../assets/blog/1-king-county/lake-sammamish3.webp"
import RiverviewImg1 from "./../../assets/blog/1-king-county/riverview-park1.webp"
import RiverviewImg2 from "./../../assets/blog/1-king-county/riverview-park2.webp"
import RiverviewImg3 from "./../../assets/blog/1-king-county/riverview-park3.webp"

// Maps
import AboretumMap from "./../../assets/blog/1-king-county/arboretum-map.webp"
import BellevueParkMap from "./../../assets/blog/1-king-county/bellevue-park-map.webp"
import DiscoveryParkMap from "./../../assets/blog/1-king-county/discovery-park-map.webp"
import FlamingGeyserMap from "./../../assets/blog/1-king-county/flaming-geyser-map.webp"
import GeneMap from "./../../assets/blog/1-king-county/gene-map.webp"
import GoldenGardensMap from "./../../assets/blog/1-king-county/golden-gardens-map.webp"
import LakeSammMap from "./../../assets/blog/1-king-county/lake-sammamish-map.webp"
import RiverTrailMap from "./../../assets/blog/1-king-county/river-trail-map.webp"
import RiverviewMap from "./../../assets/blog/1-king-county/riverview-map.webp"
import SaltwaterParkMap from "./../../assets/blog/1-king-county/saltwater-park-map.webp"
import SewardParkMap from "./../../assets/blog/1-king-county/seward-park-map.webp"
import UWMap from "./../../assets/blog/1-king-county/uw-map.webp"

export const KingCountyBlogInfo = {
  url: "/blog/king-county-photoshoot-locations",
  title: "King County Photoshoot Locations",
  desc: "King County, Washington, offers a variety of stunning locations for portrait photoshoots, from the urban charm of Seattle's Capitol Hill to the serene beauty of Green Lake Community Center. Hidden gems like the Edmonds Ferry Dock provide a classic Seattle feel, while lesser-known spots like Hickman Park in Edmonds offer diverse backdrops. For those seeking an indoor setting, Seattle's Peerspace venues boast unique spaces that cater to any style, ensuring your shoot is unaffected by the city's rainy weather. Whether you're looking for a grungy alley, a historic loft, or a picturesque park, King County's diverse locations are sure to inspire beautiful portraits.",
  img: StoryImg
}

const KingCountyLocations = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{KingCountyBlogInfo.title}</title>
        {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6242746978295253"
     crossorigin="anonymous"></script> */}
      </Helmet>

      <hr />

      <div className="blog-return">
        <Link to="/blog">
          <ArrowBackIosIcon />
        </Link>
      </div>

      <div className="blog-story">
        <h1>{KingCountyBlogInfo.title}</h1>

        <div className="blog-story-img">
          <img src={KingCountyBlogInfo.img} alt="" />
        </div>

        <p>{KingCountyBlogInfo.desc}</p>

        {/* ======================================================== */}

        <h2>Lake Sammamish State Park</h2>

        <p>Location: Sammamish, Washington</p>

        <p>
          Lake Sammamish State Park is a beautiful and scenic location for portrait photography. The park offers a variety of natural backdrops, such as the lake, the forest, the wetlands, and the meadows. You can capture stunning views of the water, the mountains, and the wildlife. The park also has some man-made features, such as picnic shelters, playgrounds, and volleyball courts, that can add some contrast and interest to your photos. Whether you are looking for a sunny or a shady spot, a calm or a lively setting, a wide or a close-up shot, you can find it at Lake Sammamish State Park.
        </p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Lake+Sammamish+State+Park,+2010+NW+Sammamish+Rd,+Issaquah,+WA+98027/@47.5878579,-122.2779644,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54906faf255d9715:0xfc18e427e1f261aa!2m2!1d-122.0588751!2d47.5584317?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={LakeSammMap} alt="Map of Lake Sammamish State Park in Washington" />
            </div>
            <div>
              <img src={LakeSammImg1} alt="Lake Sammamish State Park in Washington" />
            </div>
            <div>
              <img src={LakeSammImg2} alt="Lake Sammamish State Park in Washington" />
            </div>
            <div>
              <img src={LakeSammImg3} alt="Lake Sammamish State Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Seward Park</h2>

        <p>Location: Seattle, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Seward+Park,+5900+Lake+Washington+Blvd+S,+Seattle,+WA+98118/@47.546428,-122.3795702,11.67z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54906a210c77f0f1:0x3c791c08c886fbb8!2m2!1d-122.2573574!2d47.5496046?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={SewardParkMap} alt="Map of Seward Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Gene Coulon Memorial Beach Park</h2>

        <p>Location: Renton, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Gene+Coulon+Memorial+Beach+Park,+1201+Lake+Washington+Blvd+N,+Renton,+WA+98056/@47.5260125,-122.3233827,12.07z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54906839addab193:0xf013931cc63505a1!2m2!1d-122.2020264!2d47.5068943?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={GeneMap} alt="Map of Gene Coulon Memorial Beach Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Bellevue Downtown Park</h2>

        <p>Location: Bellevue, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Bellevue+Downtown+Park,+10201+NE+4th+St,+Bellevue,+WA+98004/@47.6083043,-122.3703867,11.53z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54906c8723915a61:0x419323e4bbee6955!2m2!1d-122.2041686!2d47.6126836?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={BellevueParkMap} alt="Map of Bellevue Downtown Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Discovery Park</h2>

        <p>Location: Seattle, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/place/Discovery+Park,+Seattle,+WA/@47.6047302,-122.4029958,12.23z/data=!4m5!3m4!1s0x549015f0e18e409f:0x519842caa4fa6320!8m2!3d47.6613197!4d-122.4171413?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={DiscoveryParkMap} alt="Map of Discovery Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>River Trail Mine Creek Day Use Area</h2>

        <p>Location: North Bend, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//F8GQ%2B9W+River+Trail+Mine+Creek+Day+Use+Area,+North+Bend,+WA+98045/@47.5218731,-122.0255614,10.82z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54907fd0faa1485d:0xd80bbece1f17a382!2m2!1d-121.6602169!2d47.475952?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={RiverTrailMap} alt="Map of River Trail Mine Creek Day Use Area in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Washington Park Arboretum</h2>

        <p>Location: Seattle, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Washington+Park+Arboretum,+2300+Arboretum+Dr+E,+Seattle,+WA+98112/@47.6364198,-122.2983895,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549014c6f6b93c81:0xa24e5c167a647e8a!2m2!1d-122.2958146!2d47.6364162?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={AboretumMap} alt="Map of Washington Park Arboretum" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>University of Washington</h2>

        <p>Location: Seattle, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//University+of+Washington,+1410+NE+Campus+Pkwy,+Seattle,+WA+98195/@47.6378401,-122.4013389,12.34z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549014929d8535eb:0x6b742c7901b82ba3!2m2!1d-122.3074579!2d47.6546407?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={UWMap} alt="Map of University of Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Golden Gardens Park</h2>

        <p>Location: Seattle, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Golden+Gardens+Park,+8498+Seaview+Pl+NW,+Seattle,+WA+98117/@47.6917553,-122.4056661,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549016648e6062ef:0x5b2c838d4c7f0aca!2m2!1d-122.4030912!2d47.6917517?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={GoldenGardensMap} alt="Map of Golden Gardens Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Flaming Geyser State Park</h2>

        <p>Location: Auburn, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Flaming+Geyser+State+Park,+23700+SE+Flaming+Geyser+Rd,+Auburn,+WA+98092/@47.2784972,-122.0364618,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x5490f47960f51c0f:0xc5d5b56102d37798!2m2!1d-122.0364618!2d47.2784972?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={FlamingGeyserMap} alt="Map of Flaming Geyser State Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Saltwater State Park</h2>

        <p>Location: Des Moines, Washington</p>

        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Saltwater+State+Pk,+25205+8th+Pl+S,+Des+Moines,+WA+98198/@47.3603978,-122.3172334,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x54905a392cb3a9df:0x60f0bd43e192b77e!2m2!1d-122.3218039!2d47.3740554?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div >
              <img src={SaltwaterParkMap} alt="Map of Saltwater State Park in Washington" />
            </div>
          </Carousel>
        </div>

        {/* ======================================================== */}

        <h2>Riverview Park</h2>

        <p>Location: Renton, Washington</p>

        <p>
          Riverview Park is a scenic and spacious park in Renton, Washington, that offers a variety of amenities and natural beauty for portrait photography. The park is part of the Cedar River Greenway System, which encompasses over 550 acres of parks, trails, and open space along the Cedar River
        </p>

        <p>Reference Photoshoot: <Link target="_blank" to={"/portfolio/ashley-2023-06"}>Ashley 2023-06</Link></p>
        <p>Get Directions: <Link target="_blank" to={"https://www.google.com/maps/dir//Riverview+Park,+3201+Maple+Valley+Hwy,+Renton,+WA+98058/@47.5075931,-122.3401811,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x549067fa94795d11:0x9be92d510ca4b992!2m2!1d-122.1805637!2d47.4772412?entry=ttu"}>Google Maps</Link></p>

        <div className="location-carousel">
          <Carousel>
            <div>
              <img src={RiverviewMap} alt="Map of Riverview Park in Washington" />
            </div>
            <div>
              <img src={RiverviewImg1} alt="Riverview Park in Washington" />
            </div>
            <div>
              <img src={RiverviewImg2} alt="Riverview Park in Washington" />
            </div>
            <div>
              <img src={RiverviewImg3} alt="Riverview Park in Washington" />
            </div>
          </Carousel>
        </div>

      </div >
    </>
  );
};

export default KingCountyLocations;