/**
 * Displays the Pierce county photo location blog page
 *
 * @file   PierceCountyLocations.jsx.
 * @author Jacob Summerville.
 * @since  02/26/2024
 */

import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./../../styles/theme.scss";
import "./../../styles/blog.scss";

import Map from "./../common/Map.jsx"

import StoryImg from "./../../assets/blog/tacoma.webp"

export const PierceCountyBlogInfo = {
  url: "/blog/pierce-county-photoshoot-locations",
  title: "Pierce County Photoshoot Locations",
  desc: "Pierce County, Washington, offers a variety of stunning locations for portrait photoshoots, from the urban charm of Tacoma's historic buildings to the natural beauty of Mount Rainier National Park. For those seeking a more natural setting, the picturesque landscapes of Point Defiance Park and the serene beaches of the Puget Sound offer perfect backdrops for any photoshoot. Whether you're looking for an indoor studio session or an outdoor adventure, Pierce County has a location to capture every moment beautifully.",
  img: StoryImg
}

const PierceCountyLocations = (props) => {

  return (
    <>
      <Helmet>
        <title>{PierceCountyBlogInfo.title}</title>
      </Helmet>

      <hr />

      <div className="blog-return">
        <Link to="/blog">
          <ArrowBackIosIcon />
        </Link>
      </div>

      <div className="blog-story">
        <h1>{PierceCountyBlogInfo.title}</h1>

        <div className="blog-story-img">
          <img src={PierceCountyBlogInfo.img} alt="" />
        </div>

        <p>{PierceCountyBlogInfo.desc}</p>

        <h2>1. TBD</h2>

        <p>Location: TBD</p>

        <div className="location-carousel">
          <Carousel>
            <div>
              {/* <img src={LakeSammImg1} /> */}
            </div>
            <div>
              {/* <img src={LakeSammImg2} /> */}
            </div>
            <div>
              {/* <img src={LakeSammImg3} /> */}
            </div>
          </Carousel>
        </div>

        <p>
          TBD
        </p>

        <div className="blog-map">
          <Map
            position={[0, 0]}
            urlLink={"#"}>
          </Map>
        </div>

      </div >
    </>
  );
};

export default PierceCountyLocations;